body{
    margin: 0px;
    font-family: Bahnschrift, Geneva, Tahoma, sans-serif;
    background-color: #ffffff;
    font-size: 13px;

}
.menu{
    height: 90px;    
    padding-top: 20px;
    background-color: white;
}
.text-menu{
    color: black;
    font-size: 14px;
    text-decoration: none;
}
.fondo-text-login{
    background-color: #c0c0c0;
    padding: 5px;
    
}
.fondo-icono{
    background-color: white;
}
.fondo-login{
    border: 1px solid #c0c0c0;
    
}
.margen-login{
    padding: 15px;
}
.pie-pagina{
    background-color: #2c2a29;
    padding-top: 30px;
    padding-bottom: 20px;
    color: white;
}
.fondo-pie{
    background-color:#ebebeb;
    padding: 15px;
}
.text-menu{
    color:white;
}
.text-pie{
    color:darkgrey;
    font-size: 14px;
}
.text-login{
    background-color: #2c2a29;
    padding-top: 30px;
    padding-bottom: 20px;
    color: white;
}
.fondo-titulo-login{
    background-color:#2c2a29;
    padding: 8px;
    color: white;
    margin: 0px;
}
.signatureCanvas{
    border: 1px solid #05298c;
    width: 100%;
    min-height: 500px;
}
.box-titulo{
    background-color:#b1dcff;
    
    border: 1px solid #82abce;
    border-radius: 5px 5px 0px 0px;
    padding-top: 10px;
    padding-left: 10px;
    -webkit-box-shadow: 1px 1px 3px 0px #9E9E9E; 
    box-shadow: 1px 1px 3px 0px #9E9E9E;
    
}
.box-form{    
    background-color:#ffffff;
    
    border: 1px solid #82abce;
    border-top: 0px;    
    border-radius: 0px 0px 5px 5px;
    padding: 10px;
    -webkit-box-shadow: 1px 1px 3px 0px #9E9E9E; 
    box-shadow: 1px 1px 3px 0px #9E9E9E;    
    
}
.box-login{    
    background-color:#ffffff;
    

    border: 1px solid #CED4DA;    
    border-radius: 5px 5px 5px 5px;
    padding: 10px;
    
    
}
.box-inicio{        
    background-color:#1FB0E3;    
    color: white;
    height: 40px;    
}
.box-fin{        
    background-color:#ABD377;    
    color: #3F571F;
    height: 25px;    
}
.titulo{
    background:#04206d;
    margin: 0px;
}
h1{
    font-size: 18px;
    color:#04206d;
    
}
h2{
    font-size: 22px;
    color:#04206d;
}

table{
    border: 1px solid #0c0254;
}
thead {
    background-color:#05298c;
    color: #b1dcff;
    border: 1px solid #0c0254;    
}
.sub-titulo{
    background:#ccdae6;    
    padding:8px;
    border-radius: 5px;
    width: 95%;
    
}
hr{
    
    border-top: 1px solid #0c0254;
    
}
.bg-light{
    background-color:red;
}
.navbar-light{
    background-color: red;
}